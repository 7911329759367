import React, { useContext } from 'react';

import { Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';

import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import ThemeContext from '../../contexts/ThemeContext';
import { OrganizationType } from '../../graphql-types';

export const CommonChatSupportPipedriveScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const appUserContext = useContext(AppUserContext);

  const miniAppUrl = 'https://pj-web-hellonext.azurewebsites.net';
  const theme = useContext(ThemeContext);
  let uri: string;

  if (appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER) {
    if (theme.theme === 'dark') {
      uri = miniAppUrl + '/courier_support_dark.html';
    } else {
      uri = miniAppUrl + '/courier_support.html';
    }
  } else if (appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY) {
    if (theme.theme === 'dark') {
      uri = miniAppUrl + '/agency_support_dark.html';
    } else {
      uri = miniAppUrl + '/agency_support.html';
    }
  } else if (appUserContext.currentUserContext?.organizationType) {
    return (
      <>
        <TopNavigationWithBackButton title="Support" navigation={navigation} />
        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
          <Text>
            Chat Support is not implement yet for{' '}
            {OrganizationType[appUserContext.currentUserContext?.organizationType] as string} users.
          </Text>
        </ScrollView>
      </>
    );
  } else {
    return (
      <>
        <TopNavigationWithBackButton title="Support" navigation={navigation} />
        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
          <LoadingSpinner subject="AppUserContext" />
        </ScrollView>
      </>
    );
  }

  const iframeStyle = { height: '100%', border: 'none' };
  return (
    <>
      <TopNavigationBackToProfileButton
        title="Support"
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />
      <iframe style={iframeStyle} src={uri} />
    </>
  );
};
